import { graphql } from 'gatsby';
import React from 'react'
import Article from '../components/Article';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import 'react-tooltip/dist/react-tooltip.css'
interface articleProps {
    data : any,
}

const article: React.FC<articleProps> = ({data}) => {

    const articleData = data.prismicBlogPost
        return (
          <Layout border='2px solid #424242' lang={articleData.lang} >
               <Seo title={`${articleData.data.title.text}`} image={articleData.data.main_image.gatsbyImageData.images.fallback.src} descriptionEn={articleData?.data?.paragraphs[0]?.paragraph?.text} descriptionFr={articleData?.data?.paragraphs[0]?.paragraph?.text} />
                <Article articleData={articleData}  />
        </Layout>
          
        );
}
export const query = graphql`
query MyQuery($id : String) {
  prismicBlogPost(id: {eq: $id}) {
    lang
    uid
    data {
      author
      date
      title {
        text
      }
      main_image {
        gatsbyImageData
      }
      paragraphs {
        paragraph {
          richText
        }
      }
      body {
        ... on PrismicBlogPostDataBodyTiktok {
          id
          primary {
            tiktok_title {
              text
            }
            link {
              url
            }
          }
          items {
            paragraph {
              text
            }
          }
          slice_type
        }
        ... on PrismicBlogPostDataBodyYoutube {
          id
          primary {
            youtube_title {
              text
            }
            link {
              url
            }
          }
          items {
            paragraph {
              text
            }
          }
          slice_type
        }
        ... on PrismicBlogPostDataBodyVimeo {
          id
          primary {
            vimeo_title {
              text
            }
            link {
              url
            }
          }
          items {
            paragraph {
              text
            }
          }
          slice_type
        }
        ... on PrismicBlogPostDataBodyInstagram {
          id
          primary {
            instagram_title {
              text
            }
            link {
              url
            }
          }
          items {
            paragraph {
              text
            }
          }
          slice_type
        }
        ... on PrismicBlogPostDataBodyTwitter {
          id
          primary {
            twitter_title {
              text
            }
            link {
              url
            }
          }
          items {
            paragraph {
              text
            }
          }
          slice_type
        }
      }
    }
  }
}
`
export default article