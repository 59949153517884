import React from 'react'
import styled from 'styled-components'
import { TikTokEmbed } from 'react-social-media-embed'
const Wrap = styled.div`
    margin: 1rem 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
color: #000000;
`
const Paragraphs = styled.div`
display: flex;
flex-direction: column;
`
const Paragraph= styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
const TikTok = styled(TikTokEmbed)`
    .tiktok-embed-container iframe{
        margin-left: -68px;
    }

`
interface TiktokProps {
    articleData: any
}

const Tiktok: React.FC<TiktokProps> = ({articleData}) => {
        return (
            <Wrap>
            <Title>
          {articleData.primary.tiktok_title.text}
            </Title>
            <div style={{display:"flex", justifyContent:"center"}}>
                {articleData.primary.link && <TikTok url={articleData.primary.link.url} width={325}/>}
            </div>
            <Paragraphs>
            {articleData.items.map((p : any,index:number) =>
                <Paragraph key={index}>
                    {p.paragraph.text}
                </Paragraph>
                )}
                    
            </Paragraphs>
        </Wrap>
        );
}
export default Tiktok