import React from 'react'
import styled from 'styled-components'
const Wrap = styled.div`
    margin: 1rem 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
color: #000000;
`
const Paragraphs = styled.div`
display: flex;
flex-direction: column;
`
const Paragraph= styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
interface VimeoProps {
    articleData :any
}

const Vimeo: React.FC<VimeoProps> = ({articleData}) => {
        return (
            <Wrap>
                <Title>
               {articleData.primary.vimeo_title.text}
                </Title>
               {articleData.primary.link && <iframe src={articleData.primary.link.url} width="100%" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>}
            <Paragraphs>
            {articleData.items.map((p:any,index:number) =>
                <Paragraph key={index}>
                    {p.paragraph.text}
                </Paragraph>
                )}
            </Paragraphs>
        </Wrap>
        );
}
export default Vimeo