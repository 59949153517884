import React from 'react'
import styled from 'styled-components'
const Wrap = styled.div`
    margin: 1rem 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
color: #000000;
`
const Paragraphs = styled.div`
display: flex;
flex-direction: column;
`
const Paragraph= styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
interface YtbProps {
    articleData: any
}

const Ytb: React.FC<YtbProps> = ({articleData}) => {
    var url = articleData.primary.link?.url.replace('https://www.youtube.com/watch?v=','https://www.youtube.com/embed/')
    
        return (
            <Wrap>
                <Title>
               {articleData.primary.youtube_title.text}
                </Title>
               {url &&  <iframe width="100%" frameBorder="0"  height="246" src={url}>
                </iframe>}
                <Paragraphs>
                {articleData.items.map((p:any,index:number) =>
                <Paragraph key={index}>
                    {p.paragraph.text}
                </Paragraph>
                )}
                </Paragraphs>
            </Wrap>
        );
}
export default Ytb