import React from 'react'
import styled from 'styled-components'
import { InstagramEmbed } from 'react-social-media-embed'

const Wrap = styled.div`
    margin: 1rem 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
color: #000000;
`
const Paragraphs = styled.div`
display: flex;
flex-direction: column;
`
const Paragraph= styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
const Ins = styled.div`
display: flex;
justify-content: center;
`
interface InstagramProps {
    articleData: any
}

const Instagram: React.FC<InstagramProps> = ({articleData}) => {
        return (
            <Wrap>
            <Title>
                {articleData.primary.instagram_title.text}
            </Title>
            <Ins>
                {articleData.primary.link && <InstagramEmbed url={articleData.primary.link.url} />}
            </Ins>
                   
            <Paragraphs>
            {articleData.items.map((p:any,index:number) =>
                <Paragraph key={index}>
                    {p.paragraph.text}
                </Paragraph>
                )}
            </Paragraphs>
        </Wrap>
        );
}
export default Instagram