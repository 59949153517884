import React from 'react'
import {TwitterTweetEmbed} from 'react-twitter-embed';
import styled from 'styled-components'
import { TwitterEmbed } from 'react-social-media-embed';
const Wrap = styled.div`
    margin: 1rem 0;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
color: #000000;
`
const Paragraphs = styled.div`
display: flex;
flex-direction: column;
`
const Paragraph= styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
`
const Tweet = styled.div`
    
`
interface TwitterProps {
    articleData:any
}

const Twitter: React.FC<TwitterProps> = ({articleData}) => {
        return (
            <Wrap>
                  <Title>
               {articleData.primary.twitter_title.text}
                </Title>
                <Tweet>
                {articleData.primary.link && <TwitterEmbed
                    url={articleData.primary.link.url}
                    width="100%"
                />}
                </Tweet>
           
            <Paragraphs>
            {articleData.items.map((p:any,index:number) =>
                <Paragraph key={index}>
              {p.paragraph.text}
                </Paragraph>
                )}
            </Paragraphs>
        </Wrap>
           
        );
}
export default Twitter